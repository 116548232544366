<template>
  <div class="sort-form">
    <div class="sort-left"
         ref="sortControl">
      <div v-for='(item,index) in controlList'
           :key="index"
           :drag-id="item.type"
           class="control-btn drag-btn">
        {{item.name}}
      </div>
    </div>
    <div class="sort-content">
      <el-button type="primary"
                 @click="addField">新增</el-button>
      <el-form :inline="true"
               :model="formData"
               ref="sortForm"
               :rules="rules"
               class="demo-form-inline">
        <el-row :draggable="true">
          <el-col v-for="(item,index) in fieldConfig"
                  :key="index"
                  :span='item.span || 8'
                  :ref="'sort-' + item.field"
                  class="drag-btn"
                  :drag-id="item.field"
                  v-show="!item.hidden">
            <el-form-item :label="item.title"
                          :prop="item.field"
                          class="form-item-sort"
                          :label-width="item.labelWidth || '120px'">
              <component :is="item.type || 'el-input'"
                         :ref="'form-' + item.field"
                         v-model="formData[item.field]"
                         v-bind="item.props" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button type="primary"
                     @click="handleSubmit">提交</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
      <el-drawer append-to-body
                 :visible.sync="drawer"
                 :direction="direction"
                 :before-close="handleClose">
        <el-row>
          <el-col :span="22">
            <el-form ref="addForm"
                     :model="addForm"
                     label-width="120px">
              <el-form-item label="标题">
                <el-input v-model="addForm.title"></el-input>
              </el-form-item>
              <el-form-item label="字段名">
                <el-input v-model="addForm.field"></el-input>
              </el-form-item>
              <el-form-item label="控件类型">
                <el-input v-model="addForm.type"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary"
                           @click="onSubmit">立即创建</el-button>
                <el-button>取消</el-button>
              </el-form-item>
            </el-form>
          </el-col>

        </el-row>
      </el-drawer>
    </div>
    <div class="sort-right">

    </div>

  </div>
</template>

<script>
import Sortable from 'sortablejs';
import control from './controlList.js';

export default {
  name: 'SortForm',
  components: {},
  data() {
    return {
      drawer: false, // 弹框显隐
      direction: 'rtl', // 弹框位置
      addForm: {}, // 弹框数据
      formData: {}, // 表单数据
      fieldConfig: [], // 配置信息
      rules: {}, // 校验信息
      fieldList: [], // 字段列表
      controlList: control.controlList, // 控件列表
    };
  },
  mounted() {
    this.rowDropleft();
    setTimeout(() => {
      const list = [
        {
          title: '编号',
          field: 'code',
          props: {
            clearable: true,
            validate: [{ required: true, trigger: 'blur' }],
          },
        },
        {
          title: '名称',
          field: 'name',
        },
        {
          title: '年龄',
          field: 'age',
        },
        {
          title: '电话',
          field: 'phone',
        },
        {
          title: '地址',
          field: 'address',
        },
      ];
      this.setFormData(list);
    }, 1000);
  },

  beforeDestroy() {
    if (this.sortForm) {
      this.sortForm.destroy();
    }
  },
  methods: {
    addField() {
      this.drawer = true;
    },
    onSubmit() {
      this.addForm.dataIndex = this.fieldConfig.length;
      this.fieldConfig.push(JSON.parse(JSON.stringify(this.addForm)));
      this.addForm = {};
      this.setFormData(this.fieldConfig);
      this.drawer = false;
    },
    handleClose(done) {
      done();
    },
    handleSubmit() {
      const list = [];
      this.fieldList.forEach((a) => {
        this.fieldConfig.forEach((b) => {
          if (b.field === a) {
            list.push(b);
          }
        });
      });
      list.forEach((v) => {
        console.log(v.title, v.field);
      });
    },
    // 设置双向绑定
    setFormData(data) {
      const f = {};
      data.forEach((v) => {
        f[v.field] = f[v.field] || '';
        if (v.props && v.props.validate) {
          this.rules[v.field] = v.props.validate || [];
        }
      });
      this.formData = f;
      this.fieldConfig = [];
      this.$nextTick(() => {
        this.fieldConfig = data;
        this.rowDropContent();
      });
    },
    rowDropleft() {
      this.sortControl = Sortable.create(this.$refs.sortControl, {
        group: {
          name: 'shared',
          pull: 'clone', // To clone: set pull to 'clone'
        },
        handle: '.drag-btn',
        animation: 150,
        onEnd: (evt) => {
          this.sortForm.el.children.forEach((v, k) => {
            const type = v.getAttribute('drag-id');
            // console.log(type);
            this.controlList.forEach((a) => {
              if (type === a.type) {
                v.remove();
                const data = {
                  field: `ext${this.fieldConfig.length}`,
                  title: `自定义${this.fieldConfig.length}`,
                  type,
                };
                this.fieldList.splice(k, 0, `ext${this.fieldConfig.length}`);
                this.fieldConfig.splice(k, 0, data);
              }
            });
          });
        // this.initFieldList();
        },
      });
    },
    // 绑定拖拽
    rowDropContent() {
      this.$nextTick(() => {
        const { sortForm } = this.$refs;
        this.sortForm = Sortable.create(sortForm.$el.querySelector('.el-row'), {
          group: {
            name: 'shared',
            pull: 'clone', // To clone: set pull to 'clone'
          },
          handle: '.drag-btn',
          animation: 150,
          ghostClass: 'blue-background-class',
          onEnd: (evt) => {
            this.initFieldList();
          },
        });
        this.initFieldList();
      });
    },
    // 初始化字段列表
    initFieldList() {
      if (this.sortForm) {
        // 初始化
        this.fieldList = [];
        this.sortForm.el.children.forEach((v) => {
          this.fieldList.push(v.getAttribute('drag-id'));
        });
        // console.log(this.fieldList);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sort-form {
  height: 100%;
  display: flex;
  .sort-left {
    width: 200px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    .control-btn {
      padding: 0 8px;
      border: 1px solid #409eff;
      border-radius: 4px;
      height: 36px;
      min-width: 80px;
      text-align: center;
      line-height: 36px;
    }
  }
  .sort-right {
    width: 200px;
    height: 100%;
  }
  .sort-content {
    flex: 1;
    padding: 20px;
    border-left: 2px solid #409eff;
    border-right: 2px solid #409eff;
    .control-btn{
      height: 33px;
      margin-bottom: 18px;
      border: 1px solid #409eff;
      width: 10%;
      opacity: 0;
    }
  }
}
/deep/.form-item-sort {
  width: 100%;
  display: flex;
  cursor: pointer;
  .el-form-item__content {
    flex: 1;
  }
}
</style>
