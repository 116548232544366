var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sort-form" }, [
    _c(
      "div",
      { ref: "sortControl", staticClass: "sort-left" },
      _vm._l(_vm.controlList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "control-btn drag-btn",
            attrs: { "drag-id": item.type },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "sort-content" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.addField } },
          [_vm._v("新增")]
        ),
        _c(
          "el-form",
          {
            ref: "sortForm",
            staticClass: "demo-form-inline",
            attrs: { inline: true, model: _vm.formData, rules: _vm.rules },
          },
          [
            _c(
              "el-row",
              { attrs: { draggable: true } },
              _vm._l(_vm.fieldConfig, function (item, index) {
                return _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.hidden,
                        expression: "!item.hidden",
                      },
                    ],
                    key: index,
                    ref: "sort-" + item.field,
                    refInFor: true,
                    staticClass: "drag-btn",
                    attrs: { span: item.span || 8, "drag-id": item.field },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "form-item-sort",
                        attrs: {
                          label: item.title,
                          prop: item.field,
                          "label-width": item.labelWidth || "120px",
                        },
                      },
                      [
                        _c(
                          item.type || "el-input",
                          _vm._b(
                            {
                              ref: "form-" + item.field,
                              refInFor: true,
                              tag: "component",
                              model: {
                                value: _vm.formData[item.field],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, item.field, $$v)
                                },
                                expression: "formData[item.field]",
                              },
                            },
                            "component",
                            item.props,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("提交")]
                ),
                _c("el-button", [_vm._v("取消")]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-drawer",
          {
            attrs: {
              "append-to-body": "",
              visible: _vm.drawer,
              direction: _vm.direction,
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.drawer = $event
              },
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 22 } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addForm",
                        attrs: { model: _vm.addForm, "label-width": "120px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "标题" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "title", $$v)
                                },
                                expression: "addForm.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "字段名" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.field,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "field", $$v)
                                },
                                expression: "addForm.field",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "控件类型" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "type", $$v)
                                },
                                expression: "addForm.type",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.onSubmit },
                              },
                              [_vm._v("立即创建")]
                            ),
                            _c("el-button", [_vm._v("取消")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "sort-right" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }