export default {
  controlList: [
    {
      type: 'el-input',
      name: '输入框',
    },
    {
      type: 'el-select',
      name: '下拉框',
    },
    {
      type: 'el-button',
      name: '按钮',
    },
  ],
};
